import { gql } from 'graphql-request'

export const badgeAchievementsQuery = () => {
  return gql`
    {
      me {
        badgeAchievements {
          id
          viewed
          createdAt
          badge {
            id
            name
            image
          }
        }
      }
    }
  `
}
