'use client'
import React from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { faHexagon } from '@fortawesome/pro-duotone-svg-icons'
import RequestedChanges from './requested-changes.png'
import ApprovedApp from './approved-app.png'
import Image from 'next/image'
import { motion } from 'framer-motion'

// import RequestedChanges from '../images/requested-changes.png'
// import ApprovedApp from '../images/approved-app.png'

const ActivityAvatar = (props) => {
  const wrapperClasses = classNames('flex flex-1 flex-col', {
    'justify-center': !props.isMobile,
  })

  return (
    <div className={wrapperClasses} style={{ maxWidth: '48px' }}>
      {(props.activityType.isProjectType || props.activityType.isFollowType) &&
        !props.activityType.isMissionType && (
          <motion.div
            whileHover={{ scale: 1.1, transition: { duration: 0.2 } }}
            className="feed-module__avatar cursor-pointer"
            style={{ minWidth: '48px' }}
            onClick={(e) => props.handleUserClick(e, props.username)}
          >
            <img
              className="avatar rounded-3xl border-2 border-white"
              src={props.avatar}
              alt=""
              style={{ width: '48px', height: '48px', borderColor: 'white' }}
            />
          </motion.div>
        )}
      {(props.activityType.isMissionType || props.activityType.isBadgeType) && (
        <div
          className="bg-titan-darkalt feed-module__icon flex items-center justify-center rounded-3xl border-2"
          style={{ minWidth: '48px', height: '48px', borderColor: 'white' }}
        >
          {props.activityType.isMissionType && (
            <FontAwesomeIcon icon={faCheck} size="lg" color="white" />
          )}
          {props.activityType.isBadgeType && (
            <FontAwesomeIcon icon={faHexagon} size="lg" color="white" />
          )}
        </div>
      )}

      {props.activityType.isAppStoreSubmissionType &&
        props.activityType.isReviewed && (
          <div className="wrapperClasses" style={{ width: '48px' }}>
            <Image
              src={RequestedChanges}
              alt="RequestChanges"
              style={{ width: '48px', height: '48px', borderColor: 'white' }}
            />
          </div>
        )}
      {props.activityType.isAppStoreSubmissionType &&
        props.activityType.isLaunched && (
          <div className="wrapperClasses" style={{ width: '48px' }}>
            <Image
              src={ApprovedApp}
              alt="LaunchedCheck"
              style={{ width: '48px', height: '48px', borderColor: 'white' }}
            />
          </div>
        )}
    </div>
  )
}

export default ActivityAvatar
