import { useQuery } from '@tanstack/react-query'
import fetchClient from '../fetch/fetchClient'
import { getStreamToken } from './UserFetch'
import useSession from './useSession'
import useAuth from '../../Hooks/Auth/useAuth'

export const useStreamToken = () => {
  const { currentUserId } = useAuth()
  return useQuery({
    queryKey: ['streamToken', currentUserId],
    queryFn: () => fetchClient(getStreamToken(currentUserId)),
    enabled: !!currentUserId,
  })
}
