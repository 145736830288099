import React from 'react'
import classNames from 'classnames'

// import { ThemeContext } from '../context/ThemeContext'
// import Button from '../Button'
// import ButtonOutline from '../ButtonOutline'

const ActivityFollowButton = (props) => {
  const followingButtonStyles = props.theme.buttons.outline

  const followClasses = classNames('flex flex-1 flex-col', {
    'items-end justify-center justify-self-end': props.align === 'right',
    'mt-8': props.align === 'left',
    'items-end': props.feedType === 'notifications',
  })

  return (
    <div className={followClasses}>
      <div className="feed-module__follow">
        {props.isFollowing && (
          <></>
          // <ButtonOutline
          //   context={'tertiaryOutline'}
          //   className={followingButtonStyles}
          //   coverClassnames={
          //     props.feedType === 'timeline'
          //       ? '!bg-neptune-darkalt'
          //       : '!bg-moon-darkest'
          //   }
          //   onClick={() => {
          //     props.onUnFollowClick(props.username, props.followingId)
          //     props.setIsFollowing(false)
          //     //props.setFollowingList(false)
          //   }}
          // >
          //   <span className="!text-white">Following</span>
          // </ButtonOutline>
        )}
        {!props.isFollowing && (
          // <Button
          //   context="tertiary"
          //   className="align-middle h-40"
          //   onClick={() => {
          //     props.onFollowClick(props.username)
          //     props.setIsFollowing(true)
          //     //props.setFollowingList(false)
          //   }}
          // >
          //   Follow
          // </Button>
          <></>
        )}
      </div>
    </div>
  )
}

export default ActivityFollowButton
