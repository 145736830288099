import { SatelliteClient } from '@codeverse/satellite-client'
import Cookies from 'js-cookie'

export const createFollowing = (username: string) => {
  const auth = localStorage.getItem('auth')
  const currentUserId = auth ? JSON.parse(auth).currentUserId : null
  return SatelliteClient.platform.post('/followings', {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    data: {
      username,
    },
    query: {
      current_user_id: currentUserId,
      // current_organization_id: organizationId,
    },
    serializer: 'followings',
  })
}

export const deleteFollowing = (followingId: string) => {
  const auth = localStorage.getItem('auth')
  const currentUserId = auth ? JSON.parse(auth).currentUserId : null
  return SatelliteClient.platform.delete(`/followings/${followingId}`, {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    query: {
      current_user_id: currentUserId,
      // current_organization_id: organizationId,
    },
  })
}

export const getUserFollowings = (userId: string) => {
  const auth = localStorage.getItem('auth')
  const currentUserId = auth ? JSON.parse(auth).currentUserId : null
  return SatelliteClient.platform.get(
    `/users/${userId || currentUserId}/followings`,
    {
      headers: Object.assign(
        {},
        { Accept: 'application/vnd.api+json' },
        { 'content-type': 'application/vnd.api+json' }
      ),
      query: {
        current_user_id: currentUserId,
      },
    }
  )
}

export const getUserFollowers = (userId: string) => {
  return SatelliteClient.platform.get(`/users/${userId}/followers`, {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
  })
}

export const getUserPublicApps = (userId: string) => {
  const auth = localStorage.getItem('auth')
  const currentUserId = auth ? JSON.parse(auth).currentUserId : null
  return SatelliteClient.platform.get(`/users/${userId}/public_apps`, {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    query: {
      access_token: localStorage.getItem('accessToken'),
      current_user_id: currentUserId,
      current_organization_id: localStorage.getItem('currentOrganizationId'),
    },
  })
}
