const activityTypes = [
  {
    verb: 'Stream::UserActivities::CreatedProject',
    action: 'created a project',
  },
  {
    verb: 'Stream::UserActivities::EarnedBadge',
    action: 'has achieved',
    selfAction: 'have achieved',
  },
  {
    verb: 'Stream::UserActivities::CompletedMission',
    action: 'completed a mission',
  },
  {
    verb: 'Stream::UserActivities::StartedMission',
    action: 'started a mission',
  },
  {
    verb: 'Stream::UserActivities::PublishedProject',
    action: 'published',
  },
  {
    verb: 'Stream::UserActivities::PublishedProjectVersion',
    action: 'published',
  },
  {
    verb: 'Stream::UserActivities::SubmittedToAppStore',
    action: 'submitted to the app store',
  },
  {
    verb: 'Stream::UserActivities::LaunchedInAppStore',
    action: 'launched in the app store',
  },
  {
    verb: 'Stream::UserActivities::SentFriendRequest',
    action: 'sent you a follow request.',
  },
  {
    verb: 'Stream::UserActivities::AcceptedFriendRequest',
    action: 'accepted your follow request.',
  },
  {
    verb: 'Stream::UserActivities::PlayedGame',
    action: 'played',
    item: 'game',
  },
  {
    verb: 'Stream::UserActivities::GameWasPlayed',
    action: 'played',
    item: 'game',
  },
  { verb: 'Stream::UserActivities::RankedUp', action: 'ranked up' },
  {
    verb: 'Stream::UserActivities::StarredProject',
    action: 'starred',
  },
  {
    verb: 'Stream::UserActivities::ProjectWasStarred',
    action: 'starred',
  },
  { verb: 'Stream::UserActivities::FollowedUser', action: 'followed' },
  { verb: 'Stream::UserActivities::NewFollower', action: 'followed you.' },
  {
    verb: 'Stream::UserActivities::AccomplishedActivity',
    action: 'accomplished an activity',
  },
  {
    verb: 'Stream::UserActivities::AddedToLeaderboard',
    action: 'added to a leaderboard',
  },
  {
    verb: 'Stream::UserActivities::PlacedOnLeaderboard',
    action: 'placed on a leaderboard',
  },
  {
    verb: 'Stream::UserActivities::AddedCommentToProjectVersion',
    action: 'commented on',
  },
  {
    verb: 'Stream::UserActivities::AddedEmojiToProjectVersion',
    action: 'added an emoji to',
  },
  {
    verb: 'Stream::UserActivities::AddedEmojiToProjectVersionComment',
    action: 'added an emoji to',
  },
  {
    verb: 'Stream::UserActivities::AddedEmojiToUserActivity',
    action: 'added an emoji to',
  },
  {
    verb: 'Stream::UserActivities::ReferredOrganization',
    action: 'shared a game',
  },
]

export function getActivityType(activity, isCurrentUser, isOwnProject) {
  const isProjectType =
    activity.includes('Project') ||
    activity.includes('Game') ||
    activity.includes('Published') ||
    activity.includes('Version') ||
    activity.includes('Starred') ||
    activity.includes('Leaderboard') ||
    activity.includes('Referred')

  const isAppStoreSubmissionType = activity.includes('AppStoreSubmission')

  const isLaunched = activity.includes('Launched')
  const isReviewed = activity.includes('Reviewed')
  const isFollowType =
    activity.includes('Friend') || activity.includes('Follow')
  const isMissionType =
    activity.includes('Mission') || activity.includes('Activity')
  const isBadgeType = activity.includes('Badge')
  const isCommentType = activity.includes('Comment')
  const isActivityType = activity.includes('Activity')

  const thisActivity = activityTypes.filter(
    (activityType) => activityType.verb === activity
  )[0]

  let activityText = thisActivity ? thisActivity.action : ''
  let activityItem = 'game'

  if (isCommentType) activityItem = 'comment'
  if (isActivityType) activityItem = 'activity'

  if (isCurrentUser && isBadgeType && thisActivity.selfAction) {
    activityText = thisActivity.selfAction
  }

  return {
    activityText: activityText,
    activityItem: activityItem,
    isProjectType: isProjectType,
    isFollowType: isFollowType,
    isMissionType: isMissionType,
    isBadgeType: isBadgeType,
    isAppStoreSubmissionType,
    isLaunched,
    isReviewed,
  }
}
