import { SatelliteClient } from '@codeverse/satellite-client'
import Cookies from 'js-cookie'

export const getBadges = (skipQueryPrefill = false) => {
  const auth = localStorage.getItem('auth')
  const currentUserId = auth ? JSON.parse(auth).currentUserId : null
  return SatelliteClient.platform.get('/badges', {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    skipQueryPrefill,
    query: {
      current_user_id: currentUserId,
      access_token: localStorage.getItem('accessToken'),
      publishable: true,
    },
  })
}

export const getCurriculumAccomplishments = () => {
  const auth = localStorage.getItem('auth')
  const currentUserId = auth ? JSON.parse(auth).currentUserId : null
  return SatelliteClient.platform.get(
    `/users/${currentUserId}/curriculum_accomplishments`,
    {
      headers: Object.assign(
        {},
        { Accept: 'application/vnd.api+json' },
        { 'content-type': 'application/vnd.api+json' }
      ),
      query: {
        current_user_id: currentUserId,
      },
    }
  )
}

export const getActivity = (activityId: string) => {
  const auth = localStorage.getItem('auth')
  const currentUserId = auth ? JSON.parse(auth).currentUserId : null

  return SatelliteClient.platform.get(`/curriculum_activities/${activityId}`, {
    headers: Object.assign(
      {},
      { Accept: 'application/vnd.api+json' },
      { 'content-type': 'application/vnd.api+json' }
    ),
    query: {
      current_user_id: currentUserId,
      access_token: localStorage.getItem('accessToken'),
    },
  })
}

export const updateBadgeAchievementViewed = (badgeAchievementId: string) => {
  const auth = localStorage.getItem('auth')
  const { currentUserId } = JSON.parse(auth || '{}')
  return SatelliteClient.platform.put(
    `/badge_achievements/${badgeAchievementId}`,
    {
      headers: Object.assign(
        {},
        { Accept: 'application/vnd.api+json' },
        { 'content-type': 'application/vnd.api+json' }
      ),
      data: {
        id: badgeAchievementId,
        viewed: true,
      },
      query: {
        current_user_id: currentUserId,
      },
      serializer: 'badge_achievements',
    }
  )
}
