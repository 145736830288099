import { useMutation, useQuery } from '@tanstack/react-query'

import fetchClientGql from '../fetch/fetchClientGql'
import { badgeAchievementsQuery } from './BadgeQueries'
import { BadgeData } from './BadgeTypes'
import {
  getBadges,
  getCurriculumAccomplishments,
  updateBadgeAchievementViewed,
} from './BadgeFetch'
import Cookies from 'js-cookie'
import { useAuthStore } from '../../Stores'

export const useBadgeAchievements = () => {
  const auth = JSON.parse(localStorage.getItem('auth') || '{}')
  const { currentUserId } = auth
  return useQuery({
    queryKey: ['badgeAchievements', auth.currentUserId],
    queryFn: () => {
      return fetchClientGql().request(
        badgeAchievementsQuery()
      ) as Promise<BadgeData>
    },
  })
}

export const useBadges = () => {
  const { isAuthenticated } = useAuthStore()
  return useQuery({
    queryKey: ['badges'],
    queryFn: () => {
      return getBadges()
    },
    enabled: isAuthenticated,
  })
}

export const useCurriculumAccomplishments = (viewProfileUserId?: string) => {
  const auth = localStorage.getItem('auth')
  const currentUserId = auth ? JSON.parse(auth).currentUserId : null
  return useQuery({
    queryKey: ['curriculumAccomplishments', currentUserId],
    queryFn: () => getCurriculumAccomplishments(),
  })
}

export const useUpdateBadgeAchievementViewed = () => {
  const mutation = useMutation({
    mutationFn: ({ badgeAchievementId }: { badgeAchievementId: string }) => {
      return updateBadgeAchievementViewed(badgeAchievementId)
    },
  })
  return mutation
}
