//@ts-nocheck
import React, { useState, useEffect, useContext, useRef } from 'react'
import { useRouter } from 'next/navigation'
import moment from 'moment-timezone'

import FeedItem from '../Nav/FeedItem'
import NotificationItem from './NotificationItem'

import { getActivityType } from '../Nav/getActivityType'
import determineAvatar from '../Nav/determineUserAvatar'
import useBadgeStore from 'apps/studio-shared/src/Stores/useBadgeStore'
import { useUIStore } from 'apps/studio-shared/src/Stores'
import { useBadges } from 'apps/studio-shared/src/Data/Achievements/BadgeDataHooks'

const projectPlacholder =
  'https://s3.amazonaws.com/uploads.codeverse.com/production/helios/icon-project-placeholder.svg'
const blankUser =
  'https://s3.amazonaws.com/uploads.codeverse.com/production/helios/blank-user.png'

export interface Props extends React.HTMLAttributes<HTMLInputElement> {
  item: any
  children: any
  currentUser: any
  sessionToken: string
  streamToken: string
  followingList: any
  setFollowingList: () => void
  ownProjectsList: any
  setOwnProjectsList: () => void
  onFollowClick: () => void
  onUnFollowClick: () => void
  onUserNameClick: () => void
  handleGameClick: () => void
  isMobile: boolean
  feedType: string
  theme: any
  isOpen: boolean
  setIsOpen: () => void
}

const ActivityContainer = function ActivityContainer(props, ref) {
  const {
    item,
    children,
    currentUser,
    sessionToken,
    followingList,
    setFollowingList,
    ownProjectsList,
    setOwnProjectsList,
    onFollowClick,
    onUnFollowClick,
    onUserNameClick,
    handleGameClick,
    isMobile,
    feedType,
    theme,
    isOpen,
    setIsOpen,
    ...other
  } = props
  const { data: badges } = useBadges()
  let primaryActivity = item.activities.filter(
    (activity) => activity.actor !== currentUser?.id
  )[0]
  if (!primaryActivity || primaryActivity === undefined) {
    primaryActivity = item.activities[0]
  }
  const [isCurrentUser, setIsCurrentUser] = useState(
    currentUser.id === primaryActivity.actor
  )
  const [screenshot, setScreenshot] = useState(projectPlacholder)
  const [actor, setActor] = useState(null)
  const [avatar, setAvatar] = useState(blankUser)
  const [username, setUsername] = useState(null)
  const [project, setProject] = useState(null)
  const [mission, setMission] = useState(null)
  const [badge, setBadge] = useState(null)
  const [isFollowing, setIsFollowing] = useState(false)
  const [followingId, setFollowingId] = useState(null)
  const [isOwnProject, setIsOwnProject] = useState(false)
  const router = useRouter()
  const { setCurrentBadge } = useBadgeStore()
  const { setBadgeModal } = useUIStore()

  const activityDate = primaryActivity.time
    ? primaryActivity.time
    : item.updated_at

  // activityDate looks like: Wed Jun 26 2024 07:20:45 GMT-0500 (Central Daylight Time)

  const createdAt = `${moment.utc(activityDate).local().fromNow()}`

  const activityType = getActivityType(item.verb, isCurrentUser, isOwnProject)
  const activityText = activityType.activityText

  // if (activityType.isBadgeType) setScreenshot(badgePlacholder)

  const isUserLoaded = useRef(false)
  const isActorInfoLoaded = useRef(false)
  const isProjectLoaded = useRef(false)
  const isMissionLoaded = useRef(false)
  const isBadgeLoaded = useRef(false)

  const mobile = window.matchMedia('(max-width: 576px)')
  const isSmallMobile = mobile.matches

  useEffect(() => {
    if (!actor && currentUser) {
      isUserLoaded.current = true
      if (primaryActivity.meta.actor) {
        setActor(primaryActivity.meta.actor)
      }
    }
    return () => {
      isUserLoaded.current = false
    }
  }, [item, actor, currentUser])

  useEffect(() => {
    if (actor !== null) {
      isActorInfoLoaded.current = true
      if (actor.profile_picture_thumbnail) {
        const testImg = new Image()
        testImg.src = actor.profile_picture_thumbnail
        if (testImg.complete) {
          setAvatar(actor.profile_picture_thumbnail)
        } else {
          testImg.onload = () => {
            setAvatar(actor.profile_picture_thumbnail)
          }
        }
      } else if (actor.avatar_id) {
        setAvatar(determineAvatar(actor.avatar_id))
      }
      const currentUsername = currentUser.username
      if (actor.username !== currentUsername) {
        setUsername(actor.username)
      }
      if (actor.username === currentUsername) {
        setUsername('You')
        setIsCurrentUser(true)
      }
    }
    return () => {
      isActorInfoLoaded.current = false
    }
  }, [actor, setAvatar])

  useEffect(() => {
    if (
      !project &&
      (primaryActivity.meta.subject.version ||
        primaryActivity.meta.subject.project) &&
      currentUser
    ) {
      isProjectLoaded.current = true
      if (item.verb.includes('Published')) {
        setProject(primaryActivity.meta.subject)
      } else if (primaryActivity.meta.subject.version) {
        setProject(primaryActivity.meta.subject.version)
      } else if (primaryActivity.meta.subject.project) {
        setProject(primaryActivity.meta.subject.project)
      } else if (ownProjectsList) {
        const _thisProject = ownProjectsList.filter(
          (project) => project.id === primaryActivity.meta.project_id
        )
        if (_thisProject.length > 0) {
          setProject(_thisProject)
        }
      }
    }
    return () => {
      isProjectLoaded.current = false
    }
  }, [actor, item, setProject])

  useEffect(() => {
    if (project) {
      for (let i = 0; i < ownProjectsList.length; i++) {
        if (ownProjectsList[i].id === project.id) {
          setIsOwnProject(true)
          break
        }
      }
    }
  }, [item, ownProjectsList, project])

  useEffect(() => {
    for (let i = 0; i < followingList.length; i++) {
      const _thisUserId = followingList[i].relationships
        ? followingList[i].relationships.following.data.id
        : followingList[i].following.id
      if (_thisUserId === primaryActivity.actor) {
        setIsFollowing(true)
        setFollowingId(followingList[i].id)
        break
      }
    }
  }, [item, followingList, primaryActivity])

  useEffect(() => {
    if (activityType.isProjectType && project) {
      let useImage = project.screenshot_thumbnail
      if (feedType === 'timeline') useImage = project.screenshot_large
      if (useImage) {
        const testImg = new Image()
        testImg.src = useImage
        if (testImg.complete) {
          setScreenshot(useImage)
        } else {
          testImg.onload = () => {
            setScreenshot(useImage)
          }
        }
      } else {
        setScreenshot(projectPlacholder)
      }
    }
  }, [screenshot, project])

  useEffect(() => {
    if (activityType.isBadgeType && badge) {
      if (badge.image_thumbnail) {
        const testImg = new Image()
        testImg.src = badge.image_thumbnail
        if (testImg.complete) {
          setScreenshot(badge.image_thumbnail)
        } else {
          testImg.onload = () => {
            setScreenshot(badge.image_thumbnail)
          }
        }
      }
    }
  }, [screenshot, badge])

  useEffect(() => {
    if (activityType.isMissionType && mission) {
      if (mission.image_thumbnail) {
        const testImg = new Image()
        testImg.src = mission.image_thumbnail
        if (testImg.complete) {
          setScreenshot(mission.image_thumbnail)
        } else {
          testImg.onload = () => {
            setScreenshot(mission.image_thumbnail)
          }
        }
      }
    }
  }, [screenshot, mission])

  useEffect(() => {
    if (activityType.isMissionType && !mission) {
      isMissionLoaded.current = true
      if (primaryActivity.meta.subject.mission) {
        setMission(primaryActivity.meta.subject.mission)
      }
    }
    return () => {
      isMissionLoaded.current = false
    }
  }, [activityType.isMissionType])

  useEffect(() => {
    if (activityType.isBadgeType && !badge) {
      isBadgeLoaded.current = true
      if (primaryActivity.meta.subject.badge) {
        setBadge(primaryActivity.meta.subject.badge)
      }
    }
    return () => {
      isBadgeLoaded.current = false
    }
  }, [activityType.isBadgeType])

  const handleOnClick = () => {
    if (feedType === 'notifications' && isOpen) setIsOpen(false)
    if (handleGameClick) {
      handleGameClick(project.id)
    } else {
      if (activityType.isProjectType) {
        router.push(`/play/${project.id}`)
      } else if (activityType.isBadgeType) {
        setBadgeModal(true)
        const badgeToFind = badges?.find(
          (badge: string) => badge.id === primaryActivity.meta.subject.badge.id
        )
        setCurrentBadge(badgeToFind)
        // window.location.href = `/?username=${currentUser.attributes.username}#play`
      } else if (activityType.isMissionType) {
        router.push(`/missions`)
      } else if (activityType.isFollowType) {
        window.location.href = `/?username=${actor.username}#play`
      } else if (activityType.isAppStoreSubmissionType) {
        router.push(`/play/${primaryActivity.meta.subject.id}`)
      }
    }
  }

  const handleUserClick = (e, profileUsername) => {
    if (feedType === 'notifications' && isOpen) setIsOpen(false)
    onUserNameClick(e, profileUsername, 'games')
  }

  return (
    <>
      {feedType === 'timeline' && activityType.isProjectType && (
        <FeedItem
          isCurrentUser={isCurrentUser}
          item={item}
          screenshot={screenshot}
          actor={actor}
          avatar={avatar}
          username={username}
          project={project}
          mission={mission}
          badge={badge}
          isFollowing={isFollowing}
          followingId={followingId}
          activityType={activityType}
          setFollowingList={setFollowingList}
          setIsFollowing={setIsFollowing}
          onFollowClick={onFollowClick}
          onUnFollowClick={onUnFollowClick}
          isMobile={isMobile}
          isSmallMobile={isSmallMobile}
          createdAt={createdAt}
          handleOnClick={handleOnClick}
          handleUserClick={handleUserClick}
          theme={theme}
          feedType={feedType}
          primaryActivity={primaryActivity}
          isOwnProject={isOwnProject}
          {...props}
        />
      )}
      {feedType === 'notifications' && (
        <NotificationItem
          isCurrentUser={isCurrentUser}
          item={item}
          screenshot={screenshot}
          actor={actor}
          avatar={avatar}
          username={username}
          project={project}
          mission={mission}
          badge={badge}
          isFollowing={isFollowing}
          followingId={followingId}
          activityType={activityType}
          setFollowingList={setFollowingList}
          setIsFollowing={setIsFollowing}
          onFollowClick={onFollowClick}
          onUnFollowClick={onUnFollowClick}
          isMobile={isMobile}
          isSmallMobile={isSmallMobile}
          createdAt={createdAt}
          handleOnClick={handleOnClick}
          handleUserClick={handleUserClick}
          theme={theme}
          feedType={feedType}
          primaryActivity={primaryActivity}
          isOwnProject={isOwnProject}
          {...props}
        />
      )}
    </>
  )
}

export default ActivityContainer
