export const alien =
  'https://s3.amazonaws.com/uploads.codeverse.com/production/helios/avatar-alien.jpg'
export const cat =
  'https://s3.amazonaws.com/uploads.codeverse.com/production/helios/avatar-cat.jpg'
export const dog =
  'https://s3.amazonaws.com/uploads.codeverse.com/production/helios/avatar-dog.jpg'
export const player =
  'https://s3.amazonaws.com/uploads.codeverse.com/production/helios/avatar-player.jpg'
export const rabbit =
  'https://s3.amazonaws.com/uploads.codeverse.com/production/helios/avatar-player.jpg'
export const spaceman =
  'https://s3.amazonaws.com/uploads.codeverse.com/production/helios/avatar-spaceman.jpg'
export const squid =
  'https://s3.amazonaws.com/uploads.codeverse.com/production/helios/avatar-squid.jpg'
export const turtle =
  'https://s3.amazonaws.com/uploads.codeverse.com/production/helios/avatar-turtle.jpg'
export const blankUser =
  'https://s3.amazonaws.com/uploads.codeverse.com/production/helios/blank-user.png'

const determineAvatar = (user: { avatar: { id: string } } | string) => {
  if (typeof user === 'object' && user?.avatar) {
    switch (user.avatar.id) {
      case 'a7a62b5d-f2a3-4c76-ae6e-7b8bd316b50e':
        return alien
      case '430d3a38-b1ee-44d1-b11c-107a11860671':
        return cat
      case '20edd67a-2ad2-4d23-b0de-e114fe39d8d8':
        return dog
      case 'f9517d14-b581-4fab-b3a1-a2885f8088c7':
        return player
      case '15143073-1633-4d98-b8b8-39f0f2f1d888':
        return rabbit
      case '1fbff479-dec5-4f7c-9aba-60e7d70f6990':
        return spaceman
      case '676e15c7-28c6-41a3-abcb-c70b21c14e3f':
        return squid
      case 'da3c7d84-8cbc-483e-8074-6784f3f7e416':
        return turtle
      default:
        return blankUser
    }
  } else if (typeof user === 'string') {
    switch (user) {
      case 'a7a62b5d-f2a3-4c76-ae6e-7b8bd316b50e':
        return alien
      case '430d3a38-b1ee-44d1-b11c-107a11860671':
        return cat
      case '20edd67a-2ad2-4d23-b0de-e114fe39d8d8':
        return dog
      case 'f9517d14-b581-4fab-b3a1-a2885f8088c7':
        return player
      case '15143073-1633-4d98-b8b8-39f0f2f1d888':
        return rabbit
      case '1fbff479-dec5-4f7c-9aba-60e7d70f6990':
        return spaceman
      case '676e15c7-28c6-41a3-abcb-c70b21c14e3f':
        return squid
      case 'da3c7d84-8cbc-483e-8074-6784f3f7e416':
        return turtle
      default:
        return blankUser
    }
  } else {
    return blankUser
  }
}

export default determineAvatar
