import React from 'react'
import Image from 'next/image'

import classNames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/pro-solid-svg-icons'

const ActivityThumbnail = (props) => {
  const containerClasses = classNames('flex flex-1 flex-col justify-self-end', {
    'items-end': props.size !== 'full',
    'justify-center': !props.isMobile,
  })

  const thumbnailStyles =
    props.size === 'full'
      ? {
          minWidth: '40px',
          maxWidth: '348px',
          marginRight: 'auto',
          marginLeft: 'auto',
          marginTop: '16px',
        }
      : { minWidth: '40px', margin: '0' }

  const screenshotStyles =
    props.size === 'full'
      ? { width: '100%' }
      : props.project || props.badge || props.mission
      ? { width: '40px', opacity: 1 }
      : { width: '40px', opacity: 0.25 }

  const thumbnailInlineStyles = `
    .feed-item__play-overlay .feed-item__play-button:hover {
      transform: scale(1.2);
    }
  `

  return (
    <div className={containerClasses}>
      <style>{thumbnailInlineStyles}</style>
      {(props.activityType.isProjectType ||
        props.activityType.isMissionType) && (
        <div
          className="feed-module__thumbnail relative cursor-pointer"
          style={thumbnailStyles}
          onClick={() => props.handleOnClick()}
        >
          <img
            alt="ProjectScreenshot"
            src={props.screenshot}
            style={screenshotStyles}
            className={props.size === 'full' ? 'rounded-3xl' : 'rounded-lg'}
          />
          {props.size === 'full' && props.activityType.isProjectType && (
            <div className="feed-item__play-overlay absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
              <div
                className="feed-item__play-button flex items-center justify-center rounded-full transition duration-500"
                style={{
                  height: '96px',
                  width: '96px',
                  backgroundColor: 'rgba(246, 251, 255, 0.3)',
                }}
              >
                <div
                  className="flex items-center justify-center rounded-full bg-white"
                  style={{ height: '78px', width: '78px' }}
                >
                  <FontAwesomeIcon
                    color="#17e898"
                    className=""
                    size="2x"
                    icon={faPlay}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {props.activityType.isLaunched && (
        <div
          className="feed-module__thumbnail relative cursor-pointer"
          style={thumbnailStyles}
          onClick={() => props.handleOnClick()}
        >
          <img
            alt="ProjectScreenshot2"
            src={props.screenshot}
            style={screenshotStyles}
            className={props.size === 'full' ? 'rounded-24' : 'rounded-8'}
          />
          {props.size === 'full' && props.activityType.isProjectType && (
            <div className="feed-item__play-overlay absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
              <div
                className="feed-item__play-button flex items-center justify-center rounded-full transition duration-500"
                style={{
                  height: '96px',
                  width: '96px',
                  backgroundColor: 'rgba(246, 251, 255, 0.3)',
                }}
              >
                <div
                  className="flex items-center justify-center rounded-full bg-white"
                  style={{ height: '78px', width: '78px' }}
                >
                  <FontAwesomeIcon
                    color="#17e898"
                    className=""
                    size="2x"
                    icon={faPlay}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {props.activityType.isBadgeType && (
        <div className="feed-module__badge" style={{ minWidth: '40px' }}>
          <img
            alt="ProjectScreenshot"
            src={props.screenshot}
            style={screenshotStyles}
          />
        </div>
      )}
    </div>
  )
}

export default ActivityThumbnail
