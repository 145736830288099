import Button from '@codeverse/helios/button'
import React from 'react'

// import { useNavigate } from 'react-router-dom'

interface ActivityViewButtonProps {
  projectId: string
}

const ActivityViewButton: React.FC<ActivityViewButtonProps> = ({
  projectId,
}) => {
  // const navigate = useNavigate()
  return (
    <div className="ml-auto flex items-center pr-2">
      <Button
        onClick={() => {
          // navigate(`/projects/${projectId}`)
        }}
        className="bg-gradient-to-b from-[#5225EE] to-[#3C19CB]"
        size="small"
        context="neptune"
      >
        View
      </Button>
    </div>
  )
}

export default ActivityViewButton
