import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface BadgeStore {
  currentBadge: any
  setCurrentBadge: (currentBadge: any) => void
  resetBadge: () => void
}

const useBadgeStore = create<BadgeStore>()(
  devtools(
    (set) => ({
      currentBadge: null,
      setCurrentBadge: (currentBadge: any) =>
        set((state) => ({
          currentBadge: currentBadge,
        })),
      resetBadge: () =>
        set((state) => ({
          currentBadge: null,
        })),
    }),
    {
      name: 'Badge Store',
    }
  )
)

export default useBadgeStore
