'use client'

import Cookies from 'js-cookie'
import { useEffect, useMemo, useState } from 'react'

export default function useAuth() {
  const authInfo = useMemo(() => {
    const auth = localStorage.getItem('auth')
    const {
      accessToken,
      currentUserId,
      username,
    }: {
      accessToken: string
      currentUserId: string
      username: string
    } = JSON.parse(auth || '{}')
    return {
      accessToken,
      currentUserId,
      username,
    }
  }, [])

  return authInfo
}
