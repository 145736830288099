import React, { useMemo } from 'react'

import ActivityAvatar from './ActivityAvatar'
import ActivityDescription from './ActivityDescription'
import ActivityFollowButton from './ActivityFollowButton'
import ActivityThumbnail from './ActivityThumbnail'
import ActivityViewButton from './ActivityViewButton'

export interface Props extends React.HTMLAttributes<HTMLInputElement> {
  isCurrentUser: boolean
  item: any
  screenshot: string
  actor: any
  avatar: string
  username: string
  project: any
  mission: any
  badge: any
  isFollowing: boolean
  setIsFollowing: () => void
  followingId: string
  setFollowingList: () => void
  onFollowClick: () => void
  onUnFollowClick: () => void
  onUserNameClick: () => void
  handleUserClick: () => void
  handleOnClick: () => void
  isMobile: boolean
  createdAt: string
  activityType: any
  theme: any
  feedType: string
  primaryActivity: any
  isOwnProject: boolean
}

const NotificationItem = function NotificationItem(props, ref) {
  const {
    isCurrentUser,
    item,
    screenshot,
    actor,
    avatar,
    username,
    project,
    mission,
    badge,
    isFollowing,
    setIsFollowing,
    followingId,
    setFollowingList,
    onFollowClick,
    onUnFollowClick,
    onUserNameClick,
    handleUserClick,
    handleOnClick,
    isMobile,
    createdAt,
    activityType,
    theme,
    feedType,
    primaryActivity,
    isOwnProject,
    ...other
  } = props

  // const { data: projectVersions } = useProjectVersions(props.project?.id, {
  //   enabled: props.activityType.isAppStoreSubmissionType && !!props.project?.id,
  // })
  // const foundProjectVersion = useMemo(() => {
  //   if (!projectVersions) return null
  //   console.log('projectVersions', projectVersions)
  //   return projectVersions?.find(
  //     (projectVersion) => projectVersion.status === 'needs_changes'
  //   )
  // }, [projectVersions])

  return (
    <div
      className="feed-module flex flex-row content-between p-4"
      style={{
        borderBottom: '1px solid RGBA(71, 76, 114, 0.35)',
      }}
    >
      <ActivityAvatar
        avatar={avatar}
        activityType={activityType}
        isMobile={isMobile}
        handleUserClick={handleUserClick}
      />

      <ActivityDescription
        username={username}
        activityType={activityType}
        handleUserClick={handleUserClick}
        handleOnClick={handleOnClick}
        project={project}
        mission={mission}
        badge={badge}
        createdAt={createdAt}
        isMobile={isMobile}
        isCurrentUser={isCurrentUser}
        feedType={feedType}
        subject={primaryActivity.meta.subject}
        foundProjectVersion={null}
        activityCount={item.activity_count}
        actorCount={item.actor_count}
        subjectType={primaryActivity.meta.subject.type}
        isOwnProject={props.isOwnProject}
      />

      {!activityType.isAppStoreSubmissionType && (
        <div>
          {activityType.isFollowType ? (
            <ActivityFollowButton
              username={username}
              followingId={followingId}
              isFollowing={isFollowing}
              onFollowClick={onFollowClick}
              onUnFollowClick={onUnFollowClick}
              setIsFollowing={setIsFollowing}
              setFollowingList={setFollowingList}
              theme={theme}
              feedType={feedType}
            />
          ) : (
            <ActivityThumbnail
              activityType={activityType}
              screenshot={screenshot}
              project={project}
              mission={mission}
              badge={badge}
              foundProjectVersion={null}
              isMobile={isMobile}
              handleOnClick={() => handleOnClick(project.id)}
              feedType={feedType}
            />
          )}
        </div>
      )}
      {activityType.isLaunched && (
        <ActivityThumbnail
          activityType={activityType}
          screenshot={
            activityType.isAppStoreSubmissionType
              ? primaryActivity.meta.subject.screenshot_thumbnail
              : screenshot
          }
          project={project}
          mission={mission}
          badge={badge}
          foundProjectVersion={null}
          isMobile={isMobile}
          handleOnClick={() => handleOnClick(project.id)}
          feedType={feedType}
        />
      )}
      {activityType.isReviewed && (
        <ActivityViewButton projectId={project?.id} />
      )}
    </div>
  )
}

export default NotificationItem
