import React, { useMemo } from 'react'
import classNames from 'classnames'
// import { useNavigate } from 'react-router-dom'

const ActivityDescription = (props) => {
  // const navigate = useNavigate()
  const isNotifications = props.feedType === 'notifications'
  const isTimeline = props.feedType === 'timeline'

  const descriptionStyles = {
    fontSize: isNotifications || props.isMobile ? '14px' : '16px',
    lineHeight: isNotifications || props.isMobile ? '18px' : '24px',
    padding: props.activityType.isAppStoreSubmissionType ? '0' : '0 10px',
  }

  const titleClasses = classNames('cursor-pointer feed-module__game-name', {
    'text-nebula': isNotifications,
    'text-sun font-jakarta-sans font-bold': isTimeline,
  })

  const titleStyles = {
    color: isNotifications ? '#ff57d0' : '#FFC745',
  }

  return (
    <div
      className="feed-module__description flex-2"
      style={{ width: props.isMobile ? 'unset' : '72%' }}
    >
      <div
        className="feed-module__description font-jakarta-sans font-medium"
        style={{}}
      >
        <p
          className={classNames('', {
            'ml-3': props.activityType.isAppStoreSubmissionType,
            'mr-2': props.activityType.isLaunched,
          })}
          style={descriptionStyles}
        >
          {props.activityType.isAppStoreSubmissionType &&
            props.activityType.isReviewed && (
              <>
                <span
                  className="feed-module_description font-jakarta-sans text-moon-light cursor-pointer font-medium"
                  onClick={() => {
                    // navigate(`/projects/${props.project.id}`)
                  }}
                >
                  Your game
                </span>

                <span
                  className="feed-module_description font-jakarta-sans text-nebula cursor-pointer font-medium"
                  onClick={() => {
                    // navigate(`/projects/${props.project.id}`)
                  }}
                >
                  {' '}
                  {props.subject.name}
                </span>
                <span
                  className="feed-module_description font-jakarta-sans text-moon-light cursor-pointer font-medium"
                  onClick={() => {
                    // navigate(`/projects/${props.project.id}`)
                  }}
                >
                  {' '}
                  needs changes before being approved.
                </span>
              </>
            )}
          {props.activityType.isAppStoreSubmissionType &&
            props.activityType.isLaunched && (
              <>
                <span
                  className="feed-module_description font-jakarta-sans cursor-pointer font-medium text-white"
                  onClick={() => {
                    props.handleOnClick()
                  }}
                >
                  Approved!
                </span>
                <span
                  className="feed-module_description font-jakarta-sans text-moon-light cursor-pointer font-medium"
                  onClick={() => {
                    props.handleOnClick()
                  }}
                >
                  {' '}
                  Your game
                </span>

                <span
                  className="feed-module_description font-jakarta-sans text-nebula cursor-pointer font-medium"
                  onClick={(e) => {
                    props.handleOnClick()
                  }}
                >
                  {' '}
                  {props.subject.name}
                </span>
                <span
                  className="feed-module_description font-jakarta-sans text-moon-light cursor-pointer font-medium"
                  onClick={(e) => {
                    props.handleOnClick()
                  }}
                >
                  {' '}
                  is
                </span>
                <span
                  className="feed-module_description font-jakarta-sans cursor-pointer font-bold text-white"
                  onClick={(e) => {
                    props.handleOnClick()
                  }}
                >
                  {' '}
                  LIVE
                </span>
                <span
                  className="feed-module_description font-jakarta-sans text-moon-light cursor-pointer font-medium"
                  onClick={(e) => {
                    props.handleOnClick()
                  }}
                >
                  {' '}
                  in the App Store.
                </span>
              </>
            )}
          {!props.activityType.isAppStoreSubmissionType && (
            <span
              className="feed-module__username font-jakarta-sans cursor-pointer font-bold text-white"
              onClick={(e) => {
                props.handleUserClick(e, props.username)
              }}
            >
              {props.username}{' '}
            </span>
          )}
          {props.activityType.isProjectType && props.actorCount > 1 && (
            <span className="feed-module__activity-count text-moon-light">
              and {props.actorCount} others{' '}
            </span>
          )}
          <span className="feed-module__message text-moon-light">
            {props.activityType.activityText}{' '}
          </span>{' '}
          {/* {isNotifications && props.isMobile && <br />} */}
          {!props.activityType.isFollowType && (
            <span
              className={titleClasses}
              style={titleStyles}
              onClick={() => props.handleOnClick()}
            >
              {props.activityType.isProjectType &&
                props.project &&
                props.project.name}
              {props.activityType.isMissionType &&
                props.mission &&
                props.mission.name}
              {props.activityType.isBadgeType &&
                props.badge &&
                props.badge.name}
            </span>
          )}
          {isTimeline &&
            props.activityType.isProjectType &&
            props.project &&
            props.project.project &&
            props.subjectType !== 'projects/version' && (
              <span className="feed-module__activity-count text-moon-light">
                &nbsp;by{' '}
                <span
                  className="font-jakarta-sans cursor-pointer font-bold text-white"
                  onClick={(e) =>
                    props.handleUserClick(
                      e,
                      props.project.project.user.username
                    )
                  }
                >
                  {props.isOwnProject && <span>You</span>}
                  {!props.isOwnProject && props.project.project.user.username}
                </span>
              </span>
            )}
          {props.activityType.isProjectType &&
            props.activityCount > 1 &&
            props.subjectType !== 'projects/version' &&
            !props.isStarring && (
              <span className="feed-module__activity-count text-moon-light">
                &nbsp;{props.activityCount} times
              </span>
            )}
        </p>
        <small>
          <span
            className="feed-module__timestamp text-moon"
            style={{ fontSize: '12px', padding: '0 12px' }}
          >
            {props.createdAt}
          </span>
        </small>
      </div>
    </div>
  )
}

export default ActivityDescription
