import React, { useMemo } from 'react'

import ActivityAvatar from '../Social/ActivityAvatar'
import ActivityDescription from '../Social/ActivityDescription'
import ActivityFollowButton from '../Social/ActivityFollowButton'
import ActivityThumbnail from '../Social/ActivityThumbnail'

export interface Props extends React.HTMLAttributes<HTMLInputElement> {
  isCurrentUser: boolean
  item: any
  screenshot: string
  actor: any
  avatar: string
  username: string
  project: any
  mission: any
  badge: any
  isFollowing: boolean
  setIsFollowing: () => void
  followingId: string
  setFollowingList: () => void
  onFollowClick: () => void
  onUnFollowClick: () => void
  handleUserClick: () => void
  handleOnClick: () => void
  isMobile: boolean
  isSmallMobile: boolean
  createdAt: string
  activityType: any
  theme: any
  feedType: string
  primaryActivity: any
  isOwnProject: boolean
}

const FeedItem = function FeedItem(props, ref) {
  const {
    isCurrentUser,
    item,
    screenshot,
    actor,
    avatar,
    username,
    project,
    mission,
    badge,
    isFollowing,
    setIsFollowing,
    followingId,
    setFollowingList,
    onFollowClick,
    onUnFollowClick,
    handleUserClick,
    handleOnClick,
    isMobile,
    isSmallMobile,
    createdAt,
    activityType,
    theme,
    feedType,
    primaryActivity,
    isOwnProject,
    ...other
  } = props

  return (
    <div
      className="feed-list__item border-b border-[#101432] p-6"
      style={{
        margin: '0',
        borderBottomWidth: '1px',
      }}
    >
      <div className="mx-auto w-full" style={{ maxWidth: '576px' }}>
        <div className="flex flex-row content-between">
          <ActivityAvatar
            avatar={avatar}
            username={username}
            activityType={activityType}
            isMobile={isMobile}
            handleUserClick={handleUserClick}
          />
          <ActivityDescription
            username={username}
            activityType={activityType}
            handleUserClick={handleUserClick}
            handleOnClick={handleOnClick}
            project={project}
            mission={mission}
            badge={badge}
            createdAt={createdAt}
            isMobile={isMobile}
            isCurrentUser={isCurrentUser}
            feedType={feedType}
            activityCount={item.activity_count}
            actorCount={item.actor_count}
            subjectType={primaryActivity.meta.subject.type}
            isOwnProject={props.isOwnProject}
            isStarring={item.verb.includes('StarredProject')}
          />

          {!isCurrentUser && !activityType.isBadgeType && !isSmallMobile && (
            <ActivityFollowButton
              username={username}
              followingId={followingId}
              isFollowing={isFollowing}
              onUnFollowClick={onUnFollowClick}
              onFollowClick={onFollowClick}
              setIsFollowing={setIsFollowing}
              setFollowingList={setFollowingList}
              align="right"
              theme={theme}
              feedType={feedType}
            />
          )}
          {activityType.isBadgeType && (
            <ActivityThumbnail
              activityType={activityType}
              screenshot={screenshot}
              project={project}
              mission={mission}
              badge={badge}
              feedType={feedType}
              isMobile={isMobile}
            />
          )}
        </div>
        {!isCurrentUser && !activityType.isBadgeType && isSmallMobile && (
          <ActivityFollowButton
            username={username}
            followingId={followingId}
            isFollowing={isFollowing}
            onUnFollowClick={onUnFollowClick}
            onFollowClick={onFollowClick}
            setIsFollowing={setIsFollowing}
            setFollowingList={setFollowingList}
            align="left"
            theme={theme}
            feedType={feedType}
          />
        )}
        {activityType.isProjectType && (
          <ActivityThumbnail
            activityType={activityType}
            screenshot={screenshot}
            project={project}
            mission={mission}
            badge={badge}
            size="full"
            isMobile={isMobile}
            handleOnClick={() => handleOnClick(project.id)}
            feedType={feedType}
          />
        )}
      </div>
    </div>
  )
}

export default FeedItem
